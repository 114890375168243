import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import classes from "./loginnew.module.css";
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
  Paper,
} from "@material-ui/core";
import axios from "axios";
import envUrls from "../../utils/config";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Mainnavbar from "../../components/mainNavbar/Mainnavbar.jsx";
import SirpiLOGO from "../../assets/Group 113.png";
import Curvelogo from "../../assets/Frame 347.png";
import windlogo from "../../assets/Windbug.png";

toast.configure();
const notify = () => {
  toast.clearWaitingQueue();
  toast("Enter valid credentials", {
    position: "top-center",
    autoClose: false,
  });
};
// toast('Enter valid credentials', {
//     position: toast.POSITION.BOTTOM_CENTER,
//     autoClose: false,
// });

const Login = () => {
  // const styles = useStyles()
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [success, setSuccess] = useState(false);
  // alert('the page was render')

  function setCookie(cname, cvalue, time) {
    var date = new Date();
    date.setTime(date.getTime() + time * 60 * 1000);
    var expires = "expires=" + date.toGMTString();
    // document.cookie = cname + "=" + cvalue + ";" + expires + ";"; //this is for local test
    document.cookie =
      cname + "=" + cvalue + ";" + expires + ";domain=.sirpi.co.in;";
  }

  function getCookie(name) {
    var found = document.cookie
      .split(";")
      .filter((c) => c.trim().split("=")[0] === name);
    return found.length > 0 ? found[0].split("=")[1] : null;
  }

  // axios defaults
  axios.defaults.withCredentials = true;
  useEffect(() => {
    var cookietoken = getCookie("Authorization_windbug");
    if (cookietoken && cookietoken !== "") {
      // window.location.href = '/issues';
    }
  });

  const data = {
    username: userName,
    password: password,
  };
  // console.log(e.target.value)
  const handleLogin = (e) => {
    axios
      .post(envUrls.baseUrl + "login", data)
      .then((response) => {
        if (response.status == 401) {
          window.location.href = "/login";
          // useHistory()
        }
        if (response.data.message === "Wrong email or password") {
          notify();
          console.log(response.data.message);
        } else {
          setCookie(
            "Authorization_windbug",
            "Bearer " + response.data.token,
            60
          );
          setCookie("email_windbug", userName, 60);
          console.log(response.data.message);
          setToken(() => response.data.token);
          setSuccess(() => !success);
          window.location.href = "/issues";
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  {
    /* <Mainnavbar /> */
  }
  return (
    <div className={classes.box}>
      <div className={classes.box1}>
        <div className={classes.head}>
          <img src={windlogo}></img>
          <p>V 2.0.3</p>
          <ToastContainer limit={1} />
        </div>
      </div>
      <div className={classes.box2}>
        <div className={classes.curve_img}>
          <img src={Curvelogo} alt="" />
        </div>

        <div className={classes.form_container}>
          <div className={classes.form__heading}>
            <h3>Welcome!</h3>
            <p>Enter your credentials</p>
          </div>
          <FormControl onSubmit={handleLogin}>
            <TextField
              size="small"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              label="Email"
              variant="outlined"
              style={{ marginTop: "20px", backgroundColor: "white" }}
            />
            <TextField
              size="small"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              variant="outlined"
              style={{ marginTop: "20px" }}
            />
            <button onClick={handleLogin} className={classes.button1}>
              Log In
            </button>
          </FormControl>
        </div>
        <div
          className={classes.sirpi}
          style={{ margin: "auto", textAlign: "center" }}
        >
          {/* <h6 style={{marginTop:'180px'}}>Powered By</h6> */}
          <img src={SirpiLOGO} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Login;
