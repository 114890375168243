import React, { useState } from "react";
import {
  Typography,
  FormLabel,
  Input,
  Paper,
  Box,
  TextField,
  TextareaAutosize,
  CardContent,
  CardActions,
  Card,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import envUrls from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import { css } from "@emotion/react";
import "./comments.css";
const Comments = (props) => {
  console.log(props, "these are props");
  const [comment, setComment] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [render, setrender] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);

  toast.configure();

  const notifyValidationsError = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
    });
  };
  const notifyValidationsWarns = (msg) => {
    toast.clearWaitingQueue();
    toast(msg, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: false,
      className: css({
        backgroundColor: "red",
      }),
    });
  };

  function getCookie(name) {
    var found = document.cookie
      .split(";")
      .filter((c) => c.trim().split("=")[0] === name);
    return found.length > 0 ? found[0].split("=")[1] : null;
  }

  const notifyValidationsSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000000,
    });
  // notifyValidationsWarns('THIS IS THE ERROR')
  // toast.isActive()
  // console.log(toast.isActive())

  async function deleteFilesInS3(filePaths) {
    try {
      const params = new URLSearchParams();
      filePaths.forEach((path) => params.append("file_paths", path));
      const response = await axios.delete(
        `${envUrls.baseUrl}delete_files?${params}`
      );
      if (response.status === 200) {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const submitComment = (fileKeys) => {
    if (attachment.length < 1) {
      setBtnDisable(true);
    }
    // console.log(fileKeys);
    if (comment !== "") {
      const fd = new FormData();
      fd.set("issue_id", props.id);
      fd.set("timestamp", Date.now());
      fd.set("comment_msg", comment);
      fd.set("email", props.email);
      const params = new URLSearchParams();
      if (attachment.length > 0) {
        fileKeys.forEach((key) => {
          params.append("file_paths", key);
        });
      }
      axios
        .post(`${envUrls.baseUrl}comment?${params.toString()}`, fd)
        .then((res) => {
          console.log(res);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          deleteFilesInS3(fileKeys);
          if (err.response.status == 401) {
            window.location.href = "/login";
            notifyValidationsWarns("Session expired, Please login again");
            sessionStorage.setItem("session_expired", "true");
          }
        });
    } else {
      setBtnDisable(false);
      notifyValidationsWarns(
        "Please Add a Comment then press on Submit button"
      );
    }
  };

  function normalizeFilename(filename) {
    // Replace non-breaking spaces with regular spaces
    return filename.replace(/\u200B|\u00A0/g, " ").trim();
  }

  async function uploadFilesToS3() {
    const fileNames = attachment.map((file) => file.name);
    const email = getCookie("email_windbug");
    const timestamp = Date.now();
    const uploadType = "issue_file";
    console.log(email);
    const params = new URLSearchParams();

    fileNames.forEach((name) => params.append("file_names", name));
    params.append("email", email.replace(/"/g, ""));
    params.append("timestamp", timestamp);
    params.append("upload_type", uploadType);

    const url = `${envUrls.baseUrl}/get_presigned_urls?${params.toString()}`;
    // const url = "";
    try {
      const response = await axios.get(url);
      if (response.data) {
        const keys = [];
        console.log(response.data, "??????");
        const uploadPromises = response.data.map(async (data) => {
          console.log("first");

          const file = attachment.find((f) => f.name === data.file_name);
          if (!file) return;
          console.log(file);
          try {
            console.log("hey");
            await axios.put(data.presigned_url, file, {
              headers: {
                "Content-Type": "application/octet-stream",
              },
              withCredentials: true,
            });
            console.log(`File uploaded: ${file.name}`);
            keys.push(data.key);
          } catch (uploadError) {
            console.error(`Error uploading file: ${file.name}`, uploadError);
          }
        });
        console.log(uploadPromises);
        await Promise.all(uploadPromises);

        if (keys.length === response.data.length) {
          submitComment(keys);
          console.log(
            "All files uploaded successfully, proceeding to handleAddIssue."
          );
        } else {
          console.warn(
            "Not all files were uploaded successfully. Skipping handleAddIssue."
          );
        }

        console.log("All files uploaded successfully");
      }
    } catch (error) {
      console.error("Error getting presigned URLs:", error);
      notifyValidationsWarns(
        "Something went wrong while uploading the file(s)"
      );
    }
  }
  function handleSubmitComment() {
    if (attachment.length > 0) {
      uploadFilesToS3();
    } else {
      submitComment();
    }
  }

  const submitOpenCloseIssue = () => {
    if (comment !== "") {
      const fd = new FormData();
      fd.set("issue_id", props.id);
      fd.set("timestamp", Date.now());
      fd.set("comment_msg", comment);
      fd.set("email", props.email);
      // fd.set('attachment', attachment)
      if (props.performActionStatus === "Close") {
        fd.set("close_by", props.email);
      } else {
        fd.set("reopen_by", props.email);
      }
      if (attachment) {
        for (var i = 0; i < attachment.length; i++) {
          console.log("present", attachment[i]);
          fd.append("attachment", attachment[i]);
        }
      }
      axios
        .post(envUrls.baseUrl + "comment", fd)
        .then((res) => {
          console.log(res.data.message, "yahan haui");

          if (res.data.message) {
            notifyValidationsWarns(res.data.message);
          } else {
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 401) {
            window.location.href = "/login";
            notifyValidationsWarns("Session expired, Please login again");
            sessionStorage.setItem("session_expired", "true");
          }
        });
    } else {
      notifyValidationsWarns("Please Add a Comment then press on close button");
    }
  };

  const openFileInput = () => {
    document.getElementById("fileupload").click();
  };

  let fileTypeallowe = [
    "pdf",
    "jpg",
    "png",
    "docx",
    "jpeg",
    "ppt",
    "txt",
    "xls",
    "pptx",
    "xlsx",
    "zip",
    "7z",
    "csv",
    "jam",
    "rtf",
    "tab",
    "kml",
    "fig",
  ];
  const handleFile = (event) => {
    const file = event.target.files[0];
    console.log(file.name.split("."), "././ppppppp");
    let [filetype] = file.name.toLowerCase().split(".").slice(-1);
    console.log(filetype, ".....");
    if (fileTypeallowe.includes(filetype)) {
      // setAttachment([...attachment, file]);
      setAttachment((prev) => prev.concat(file));
    } else {
      notifyValidationsWarns("File Type not allowed");
    }
    // window.location.reload();
    console.log(file);
    notifyValidationsWarns(
      "Please Add a Comment then press on Submit/ Close button"
    );
  };
  console.log(attachment);
  let deletefileinput = (event, i) => {
    // incomplete for now
    console.log(i, attachment);
    setAttachment(attachment.filter((_, id) => id != i));
  };

  return (
    <Card
      elevation={3}
      style={{ marginLeft: "9px", marginBottom: "10px", marginTop: "10px" }}
    >
      {/* <div style={{ borderBottom: 'solid 3px lightgrey', width: '50%', marginLeft: '25%', marginTop: 17 }}></div> */}
      <div className="main_class" style={{ padding: 10, marginTop: "10px" }}>
        <Typography
          style={{ fontWeight: 500, fontSize: "15px" }}
          variant="h5"
          gutterBottom
        >
          Closing/Re-opening Comment
        </Typography>
        <TextareaAutosize
          maxRows={11}
          minRows={10}
          mincols={100}
          aria-label="maximum height"
          placeholder="Type your additional/closing/reopening comment here"
          style={{ width: "600px", padding: "20px", height: 120 }}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
        <div style={{ display: "flex" }}>
          {attachment.map((val, i) => (
            <p style={{ marginLeft: "8px" }}>
              {val.name}{" "}
              <i
                className="fa fa-trash text-danger"
                style={{ fontSize: "18px", marginLeft: "5px" }}
                onClick={deletefileinput.bind(null, val, i)}
              ></i>{" "}
            </p>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
            height: 50,
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          {/* <Button variant="contained" color="primary" disabled={props.email !== props.issuerEmail && props.email !== 'tilak@sirpi.io'} style={{ marginLeft: '20px', marginTop: '5px' }} onClick={submitOpenCloseIssue}>
                {props.performActionStatus} Issue
            </Button> */}
          <Button
            disabled={attachment.length == 1}
            variant="contained"
            component="label"
            size="small"
            // color="primary"
            style={{
              borderRadius: "10px",
              backgroundColor: "#004AAD",
              color: "white",
              textTransform: "none",
            }}
            // onClick={openFileInput}
          >
            Attach File
            <input
              type="file"
              style={{ display: "none" }}
              id="fileupload"
              onChange={handleFile}
            />
          </Button>
          <Button
            className="dis_butt"
            disabled={btnDisable}
            size="small"
            variant="contained"
            onClick={handleSubmitComment}
            style={{
              borderRadius: "10px",
              backgroundColor: "#004AAD",
              color: "white",
              textTransform: "none",
            }}
          >
            Submit
            {btnDisable && (
              <CircularProgress
                style={{ color: "white", marginLeft: "5px" }}
                size={20}
              />
            )}
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={submitOpenCloseIssue}
            style={{
              borderRadius: "10px",
              backgroundColor: "#004AAD",
              color: "white",
              textTransform: "none",
            }}
          >
            {props.performActionStatus} {props.fileType}
          </Button>
          <ToastContainer limit={1} />
        </div>
      </div>
    </Card>
  );
};

export default Comments;
